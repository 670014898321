$(document).ready(function ($) {
	$('.tabs').liteTabs();
});

// Lite tabs (záložkové menu)
(function ($) {

	$.fn.liteTabs = function (options) {

		return this.each(function () {

			var defaults = {
				borders: false,
				boxed: false,
				fadeIn: true,
				fadeInSpeed: 500,
				height: 'auto',
				hideHash: false,
				hashTag: true,
				selectedTab: 1,
				width: 500
			},
			// merge defaults with options in new settings object
			settings = $.extend({}, defaults, options),
					// define key variables
					$this = $(this),
					$ul = $this.find('.tab-nav').first(),
					$tab = $ul.find('a'),
					$tabSwitch = $this.find('.tab-switch'),
					$div = $this.find('.tab-content').first().find('.tab');

			// option: set overall height
			$div.css({
				position: 'absolute',
				left: -9999
			});

			// on tab click...
			$tab.click(function (e) {
				var selectedPanel = $div.filter('[name="' + this.hash + '"]');
				var filterHash = $div.removeClass('selected').filter('[name="' + this.hash + '"]');

				// defaults: add selected class to tab
				$tab.removeClass('selected').filter(this).addClass('selected');

				// Rodičovská záložka zůstane otevřená při kliku na vnořené záložky v záložce
				var parentId = $tab.filter(this).closest('.tab').attr('name');
				$('.tab[name="' + parentId + '"]').addClass('selected');
				$('.tab-nav a[href="' + parentId + '"]').addClass('selected');

				if ($tab.parents('.tabs').length > 1) {
					e.preventDefault();
				}

				// Otevře první vnořenou záložku
				selectedPanel.find('.tab-nav').find('li').first().find('a').click();

				// option: fade in divs
				if (settings.fadeIn) {
					filterHash.hide().addClass('selected').fadeIn(settings.fadeInSpeed);
				} else {
					filterHash.addClass('selected');
				}

				// option: hide hash change
				if (settings.hideHash) {
					e.preventDefault();
				}
			});

			$tabSwitch.click(function (e) {
				var filterHash = $div.removeClass('selected').filter('[name="' + this.hash + '"]');

				// defaults: add selected class to tab
				$tabSwitch.removeClass('selected').filter(this).addClass('selected');

				// option: fade in divs
				if (settings.fadeIn) {
					filterHash.hide().addClass('selected').fadeIn(settings.fadeInSpeed);
				} else {
					filterHash.addClass('selected');
				}

				// option: hide hash change
				if (settings.hideHash) {
					e.preventDefault();
				}
			});

			// option: set selected tab
			if (settings.selectedTab) {
				$tab.eq(settings.selectedTab - 1).addClass('selected');
				$div.eq(settings.selectedTab - 1).addClass('selected');
			}

			// If hash tag..
			var hash = window.location.hash.substring(1);
			if (hash) {
				$('.tab').addClass('tab-not-animated');
				$('[href="#' + hash + '"]').click();
				$('.tab').removeClass('tab-not-animated');
			}
		});

	};

})(jQuery);
