 jQuery(function(){
	var body = $('body'),
		menuTrigger = $('.menu-trigger'),
		dropdownTriggers = $('.dropdown-trig');

	menuTrigger.on('click', function(e) {
		e.preventDefault();
		body.toggleClass('menu-open');
	});

	dropdownTriggers.each(function() {
		var trigger = $(this),
			elId = trigger.data('dropdown'),
			dropdown = $('#' + elId);
		if(dropdown.length) {
			trigger.on('click', function(e) {
				if($(window).width() > 1199) {
					e.preventDefault();
					var wasOpened = dropdown.hasClass('dropdown-open');

					$('.dropdown').slideUp(200, function() { $('.dropdown').removeClass('dropdown-open'); });
					$('.dropdown-trig').removeClass('dropdown-trig-active');
					if(!wasOpened) {
						// close langs or search
						$('#langs').removeClass('langs-open');
						$('#search').hide();

						dropdown.slideDown(200, function() { dropdown.addClass('dropdown-open'); });
						trigger.addClass('dropdown-trig-active');
						body.addClass('dropdown-open');
					}
					else {
						setTimeout(function(){ body.removeClass('dropdown-open')}, 150);
					}
				}
			});
		}
	});

	$(document).bind('click', function (e) {
		if (!$('.main-nav').is(e.target) && $('.main-nav').has(e.target).length === 0) {
			hideDropdowns();
		}
	});

	function hideDropdowns() {
		$('.dropdown').slideUp(200, function() { $('.dropdown').removeClass('dropdown-open'); });
		$('.dropdown-trig').removeClass('dropdown-trig-active');
		setTimeout(function(){ body.removeClass('dropdown-open')}, 150);
	}

	/* lng switch */
	$('.lang-switch').on('click', function(e) {
		e.preventDefault();
		var langsList = $('#langs');
		if(langsList.length > 0) {
			langsList.toggleClass('langs-open');
		}
	});

	/* social nets */
	$('.social-nets-switch').on('click', function(e) {
		e.preventDefault();
		var netsList = $('#social-nets');
		if(netsList.length > 0) {
			netsList.toggleClass('social-nets-open');
		}
	});

	$('.fix-nav').each(function() {
		var nav = $(this),
			navTop = nav.offset().top;

		fixNav(nav, navTop);

		$(window).on('scroll', function(){
			fixNav(nav, navTop);
		});
	});

	function fixNav(nav, navTop) {
		if ($(window).scrollTop() >= navTop) {
			nav.addClass('fixed');
		}
		else {
			nav.removeClass('fixed');
		}
	}
 });